import React, { Component } from "react";
import Wrapper from "../Wrapper";
import FrameWork from '../../images/framework.png';
import c3 from 'c3';
import axios from 'axios';

class TanzaniaScoreboard extends Component {
    async componentDidMount() {
        const [enablerData, inputData, linkagesData, outputData, impactData] = await Promise.all([
            axios.get('chart/?indicator=enabler&country=Tanzania'),
            axios.get('chart/?indicator=input&country=Tanzania'),
            axios.get('chart/?indicator=linkages&country=Tanzania'),
            axios.get('chart/?indicator=output&country=Tanzania'),
            axios.get('chart/?indicator=impact&country=Tanzania'),
        ]);

        this.generateChart({target: 'chart-enabler', top_target: 'chart-enabler-top',
            indicator: 'enabler', records: enablerData.data.data});
        this.generateChart({target: 'chart-input', top_target: 'chart-input-top',
            indicator: 'input', records: inputData.data.data});
        this.generateChart({target: 'chart-linkages', top_target: 'chart-linkages-top',
            indicator: 'linkages', records: linkagesData.data.data});
        this.generateChart({target: 'chart-outputs', top_target: 'chart-outputs-top',
            indicator: 'output', records: outputData.data.data});
        this.generateChart({target: 'chart-impacts', top_target: 'chart-impacts-top',
            indicator: 'impact', records: impactData.data.data});
    }

    generateChart = ({target, top_target, indicator, records}: { target: any, top_target: any, indicator: string,
        records: any }) => {
        const chart = c3.generate({
            bindto: `#${target}`,
            size: {
                height: 250,
                width: 550
            },
            data: {
                x: 'country',
                columns: [['country'], ['Counts']],
                type: 'bar',
                labels: true,
                onclick: async function (d, element) {

                    const top_chart = c3.generate({
                        bindto: `#${top_target}`,
                        size: {
                            height: 250,
                            width: 550
                        },
                        data: {
                            x: 'indicator',
                            columns: [
                                ['indicator'],
                                ['value'],
                                ['rank']],
                            type: 'bar',
                            labels: true,
                        },
                        axis: {
                            rotated: true,
                            x: {
                                type: 'category'
                            }
                        },
                        legend: {
                            show: true
                        }
                    })

                    const response = await axios.get(`chart-top/?indicator=${indicator}&country=${records[d.index].country}`);
                    const top_records: { indicator: string, value: number, rank: number }[] = response.data.data;
                    console.log(top_records);

                    top_chart.load({
                        columns: [
                            ['indicator', ...top_records.map((r: { indicator: string; }) => r.indicator
                                .slice(0, 40).replace(/\s\S*$/, ''))],
                            ['value', ...top_records.map((r: { value: number; }) => r.value)],
                            ['rank', ...top_records.map((r: { rank: number; }) => r.rank)]
                        ],
                    })
                }
            },
            axis: {
                rotated: true,
                x: {
                    type: 'category'
                },
                y: {
                    label: 'Number of STI indicators'
                }
            },
            legend: {
                show: true
            }
        });

        const xValues = records.map((r: { country: string; }) => r.country);
        const counts = records.map((r: { counts: number; }) => r.counts);

        chart.load({
            columns: [
                ['country', ...xValues],
                ['Counts', ...counts]
            ]
        });
    };

    render() {
        return (
            <Wrapper>
                <div className="d-flex justify-content-center">
                    <h2>Logical Framework</h2>
                </div>

                <div className="d-flex justify-content-center">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div>
                            <img src={FrameWork} alt="STI Framework"/>
                        </div>
                    </div>
                </div>

                {/*enabler*/}
                <div className="row clearfix">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h2>Enabler</h2>
                                <div id='chart-enabler'/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h4>Top 5 indicators by Quality Ranking (Low numbers best rank)</h4>
                                <div id='chart-enabler-top'/>
                            </div>
                        </div>
                    </div>
                </div>

                {/*input*/}
                <div className="row clearfix">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h2>Input</h2>
                                <div id='chart-input'/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h4>Top 5 indicators by Quality Ranking (Low numbers best rank)</h4>
                                <div id='chart-input-top'/>
                            </div>
                        </div>
                    </div>
                </div>


                {/*linkages*/}
                <div className="row clearfix">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h2>Linkages</h2>
                                <div id='chart-linkages'/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h4>Top 5 indicators by Quality Ranking (Low numbers best rank)</h4>
                                <div id='chart-linkages-top'/>
                            </div>
                        </div>
                    </div>
                </div>


                {/*outputs*/}
                <div className="row clearfix">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h2>Outputs</h2>
                                <div id='chart-outputs'/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h4>Top 5 indicators by Quality Ranking (Low numbers best rank)</h4>
                                <div id='chart-outputs-top'/>
                            </div>
                        </div>
                    </div>
                </div>

                {/*impacts*/}
                <div className="row clearfix">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h2>Impacts</h2>
                                <div id='chart-impacts'/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                        <div className="card">
                            <div className="body">
                                <h4>Top 5 indicators by Quality Ranking (Low numbers best rank)</h4>
                                <div id='chart-impacts-top'/>
                            </div>
                        </div>
                    </div>
                </div>

            </Wrapper>
        );
    }
}

export default TanzaniaScoreboard;

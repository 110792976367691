import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {User} from "../../classes/user";
import {connect} from "react-redux";


class Menu extends Component<{ user: User }> {

    menuItems = [
        {
            link: '/users',
            name: 'Users'
        },
        {
            link: '/roles',
            name: 'Roles'
        },
        {
            link: '/surveys',
            name: 'Survey'
        },
        {
            link: '/forms',
            name: 'Form'
        },
        {
            link: '/submissions',
            name: 'Submission'
        }
    ]

    render() {

        let menu: JSX.Element[] = [];

        this.menuItems.forEach(item => {
            if (this.props.user.canView(item.name.toLowerCase())) {
                menu.push(
                    <li className="nav-item">
                        <NavLink to={item.link} className="nav-link">
                            {item.name}
                        </NavLink>
                    </li>
                );
            }
        });

        return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light collapse show">
                <div className="sidebar-sticky pt-3">
                    <ul className="nav flex-column flex-nowrap overflow-hidden">
                        <li className="nav-item">
                            <NavLink to={'/dashboard'} className="nav-link">
                                Dashboard
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/scoreboard'} className="nav-link">
                                Scoreboard
                            </NavLink>
                        </li>
                        Countries
                        <li className="nav-item">
                            <NavLink to={'/countries/burundi'} className="nav-link">
                                Burundi
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/countries/kenya'} className="nav-link">
                                Kenya
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/countries/rwanda'} className="nav-link">
                                Rwanda
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/countries/south-sudan'} className="nav-link">
                                South Sudan
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/countries/tanzania'} className="nav-link">
                                Tanzania
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/countries/uganda'} className="nav-link">
                                Uganda
                            </NavLink>
                        </li>
                        Data Management
                        {menu}
                    </ul>
                </div>
            </nav>
        )
    }

}


// @ts-ignore
export default connect(state => ({user: state.user}))(Menu);
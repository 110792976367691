import React, {Component} from 'react';
import {User} from "../../classes/user";
import Wrapper from "../Wrapper";
import {Link} from "react-router-dom";
import axios from "axios";
import Paginator from "../components/Paginator";
import Deleter from "../components/Deleter";
import {Form} from "../../classes/form";

class Forms extends Component<{ user: User }> {

    state = {
        forms: []
    }

    page = 1;
    last_page = 0;

    componentDidMount = async () => {
        const response = await axios.get(`surveys/forms/?page=${this.page}`);

        console.log(response);

        this.setState({
            forms: response.data.data
        })

        this.last_page = response.data.meta.last_page;

    }

    handleDelete = async (id: number) => {
        this.setState({
            forms: this.state.forms.filter((f: Form) => f.id !== id)
        })
    }

    handlePageChange = async (page: number) => {
        this.page = page;

        await this.componentDidMount();
    }

    render() {
        return (
            <Wrapper>
                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={'/products/create'} className="btn btn-sm btn-outline-secondary">Add</Link>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Project ID</th>
                            <th>Country</th>
                            <th>Link</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.forms.map(
                            (form: Form) => {
                                return (
                                    <tr key={form.id}>
                                        <td>{form.id}</td>
                                        <td>{form.name}</td>
                                        <td>{form.survey}</td>
                                        <td>{form.country}</td>
                                        <td>{form.link}</td>
                                        <td>
                                            <div className="btn-group mr-2">
                                                <Link to={`/forms/${form.id}/edit`}
                                                      className="btn btn-sm btn-outline-secondary">Edit</Link>
                                                <Deleter id={form.id} endpoint={'surveys/forms/'}
                                                         handleDelete={this.handleDelete}/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        )}

                        </tbody>
                    </table>
                </div>

                <Paginator lastPage={this.last_page} handlePageChange={this.handlePageChange}/>

            </Wrapper>
        )
    }
}

export default Forms;
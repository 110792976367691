import React from 'react';
import './App.css';
import Users from "./secure/users/Users";
import {BrowserRouter, Route} from "react-router-dom";
import Login from "./public/Login";
import register from "./public/Register";
import Dashboard from "./secure/dashboard/Dashboard";
import RedirectToDashboard from "./secure/users/RedirectToDashboard";
import UserCreate from "./secure/users/UserCreate";
import UserEdit from "./secure/users/UserEdit";
import Roles from "./secure/roles/Roles";
import RoleCreate from "./secure/roles/RoleCreate";
import RoleEdit from "./secure/roles/RoleEdit";
import Surveys from "./secure/surveys/Surveys";
import Forms from "./secure/forms/Forms";
import Submissions from "./secure/submissions/Submissions";
import Profile from "./secure/profile/Profile";
import Scoreboard from "./secure/scoreboard/Scoreboard";
import KenyaScoreboard from "./secure/countries/KenyaScoreboard";
import BurundiScoreboard from "./secure/countries/BurundiScoreboard";
import RwandaScoreboard from "./secure/countries/RwandaScoreboard";
import SouthSudanScoreboard from "./secure/countries/SouthSudanScoreboard";
import TanzaniaScoreboard from "./secure/countries/TanzaniaScoreboard";
import UgandaScoreboard from "./secure/countries/UgandaScoreboard";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Route path={'/'} exact component={RedirectToDashboard}/>
                <Route path={'/dashboard'} exact component={Dashboard}/>
                <Route path={'/profile'} component={Profile} exact/>
                <Route path={'/login'} component={Login}/>
                <Route path={'/register'} component={register}/>
                <Route path={'/users'} component={Users} exact/>
                <Route path={'/users/create'} component={UserCreate} />
                <Route path={'/users/:id/edit'} component={UserEdit} />
                <Route path={'/roles'} component={Roles} exact/>
                <Route path={'/roles/create'} component={RoleCreate}/>
                <Route path={'/roles/:id/edit'} component={RoleEdit}/>
                <Route path={'/surveys'} component={Surveys} exact/>
                <Route path={'/forms'} component={Forms} exact/>
                <Route path={'/submissions'} component={Submissions} exact/>
                <Route path={'/scoreboard'} component={Scoreboard} exact/>
                <Route path={'/countries/burundi'} component={BurundiScoreboard} exact/>
                <Route path={'/countries/kenya'} component={KenyaScoreboard} exact/>
                <Route path={'/countries/rwanda'} component={RwandaScoreboard} exact/>
                <Route path={'/countries/south-sudan'} component={SouthSudanScoreboard} exact/>
                <Route path={'/countries/tanzania'} component={TanzaniaScoreboard} exact/>
                <Route path={'/countries/uganda'} component={UgandaScoreboard} exact/>
            </BrowserRouter>

        </div>
    );
}

export default App;

import React, {Component, SyntheticEvent} from "react";
import {Role} from "../../classes/role";
import Wrapper from "../Wrapper";
import axios from "axios";
import {Redirect} from "react-router-dom";

class UserCreate extends Component {
    state = {
        roles: [],
        redirect: false,
        country_name: '',
        readOnly: true
    }
    first_name = '';
    last_name = '';
    country_name = '';
    email = '';
    role_id = 0;

    componentDidMount = async () => {
        const response = await axios.get('roles');
        const userCall = await axios.get('user/');
        const country = userCall.data.data.country;

        this.setState({
            roles: response.data.data,
            country_name: country

        })

        console.log("Country", country);

    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.post('users/?type=create', {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            role_id: this.role_id,
            country_name: this.country_name
        });

        this.setState({
            redirect: true
        })

        console.log(
            this.first_name,
            this.last_name,
            this.email,
            this.role_id,
            this.country_name
        )
    }


    render() {
        if(this.state.redirect) {
            return <Redirect to={'/users'}/>;
        }

        return (
            <Wrapper>
                <form onSubmit={this.submit}>
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="text" className="form-control" name="first_name"
                               onChange={e => this.first_name = e.target.value}
                        />
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" className="form-control" name="last_name"
                               onChange={e => this.last_name = e.target.value}
                        />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" name="email"
                               onChange={e => this.email = e.target.value}
                        />
                    </div>
                    <div className="form-group">
                        <label>Country</label>
                        <input type="text" className="form-control" name="country"
                               value={this.country_name = this.state.country_name}
                               readOnly={this.state.readOnly}
                        />
                    </div>

                    <div className="form-group">
                        <label>Role</label>
                        <select name="role_id" className="form-control"
                                onChange={e => this.role_id = parseInt(e.target.value)}
                        >
                            <option>Select Role</option>
                            {this.state.roles.map(
                                (role: Role) => {
                                    return (
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    )
                                }
                            )}
                        </select>
                    </div>
                    <button className="btn btn-outline-secondary">Save</button>
                </form>
            </Wrapper>
        );
    }
}

export default UserCreate;
import React, {Component} from 'react';
import {User} from "../../classes/user";
import Wrapper from "../Wrapper";
import {Link} from "react-router-dom";
import axios from "axios";
import {Indicators} from "../../classes/indicators";
import Paginator from "../components/Paginator";

class Scoreboard extends Component<{ user: User }> {

    state = {
        indicators: []
    }

    page = 1;
    last_page = 0;

    componentDidMount = async () => {
        const response = await axios.get(`scoreboard/?page=${this.page}`);

        console.log(response.data.data);

        this.setState({
            indicators: response.data.data
        })

        this.last_page = response.data.meta.last_page;

    }

    handlePageChange = async (page: number) => {
        this.page = page;

        await this.componentDidMount();
    }

    render() {
        return (
            <Wrapper>
                <div>
                    Scoreboard
                </div>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>SHORT_NAME</th>
                            <th>INDICATORSOURCEID</th>
                            <th>INDICATORNAME</th>
                            <th>SOURCE_ORIGIN</th>
                            <th>VALUE</th>
                            <th>LATEST_YEAR</th>
                            <th>RANKING</th>
                            <th>LOGICAL_FRAMEWORK_COMPONENT</th>
                            <th>ACTORS</th>
                            <th>STI_ACTIVITIES_MAIN_SUBJECTS</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.indicators.map(
                            (indicators: Indicators) => {
                                return (
                                    <tr key={indicators.id}>
                                        <td>{indicators.id}</td>
                                        <td>{indicators.short_name}</td>
                                        <td>{indicators.indicatorsourceid}</td>
                                        <td>{indicators.indicatorname}</td>
                                        <td>{indicators.source_origin}</td>
                                        <td>{indicators.value}</td>
                                        <td>{indicators.latest_year}</td>
                                        <td>{indicators.ranking}</td>
                                        <td>{indicators.logical_framework_component}</td>
                                        <td>{indicators.actors}</td>
                                        <td>{indicators.sti_activities_main_subjects}</td>
                                    </tr>
                                )
                            }
                        )}

                        </tbody>
                    </table>
                </div>

                <Paginator lastPage={this.last_page} handlePageChange={this.handlePageChange}/>

            </Wrapper>
        )
    }
}

export default Scoreboard;